<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >{{ capitalizeFirstLetter(action) }} Program Exercise</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <AddExercises
            :week="current_program"
            :exercises="exercises"
            :categories="categories"
            :currentDay="currentDay"
            :altExercises="altExercises"
            :isEdit="isEdit"
            :show-main-search="true"
            @addDay="addDay"
            @onLoadExercises="loadExercises"
            @onEdit="onEdit"
            @onRemove="onRemove"
            @onDuplicateDay="onDuplicateDay"
            @update="update"
            @enableWeek="updateEnableWeek"
            @onLoadMoreAltExercises="loadMoreAltExercises"
            @onLoadMoreExercises="loadMoreExercises"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="formActions('cancel')">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :loading="programLoading"
          :disabled="!enableWeek"
          @click="formActions('save')"
        >
          {{ capitalizeFirstLetter(action) }} Week
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddExercises from '@/components/program/AddExercises'
import { mapActions, mapMutations, mapState } from 'vuex'
import { objectToFormData } from '@/utils/jsonToFormData'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: 'Add',
    },
    exercises: {
      type: Array,
      required: true,
    },
    altExercises: {
      type: Array,
      required: false,
    },
    categories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isEditWeek: {
      type: Boolean,
      default: false,
    },
    current_program: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentDay: {
        day: null,
        category_id: null,
        contents: [],
        thumb_nail: null,
        custom_name: null,
      },
      isEdit: false,
      editIndex: null,
      enableWeek: false,
    }
  },
  components: {
    AddExercises,
  },
  mounted() {
    this.currentDay.day = this.current_program.daily_exercises.length + 1
    this.loadAllExercises({ type: 'alt' })
  },
  computed: {
    ...mapState({
      programLoading: (state) => state.program.programLoading,
      exercisesMeta: (state) => state.exercise.exercises?.meta,
      altExercisesStore: (state) => state.exercise.altExercises,
    }),
  },
  methods: {
    ...mapActions({
      getExercises: 'exercise/getExercises',
      addProgramDayExercise: 'program/addProgramDayExercise',
      deleteProgramDayExercise: 'program/deleteProgramDayExercise',
      duplicateProgramDayExercise: 'program/duplicateProgramDayExercise',
    }),
    ...mapMutations({
      clearExercises: 'exercise/clearExercises',
    }),
    formActions(action) {
      if (action === 'cancel') {
        this.$emit('onCancel')
        this.clearExercises()
        this.resetCurrentDay()
      } else if (this.isEditWeek) {
        this.$emit('onUpdate', this.current_program)
        this.clearExercises()
        this.resetCurrentDay()
      } else {
        this.$emit('onSave', this.current_program)
        this.resetCurrentDay()
        this.clearExercises()
      }
    },
    async addDay() {
      if (this.$route.params.id && this.isEditWeek) {
        if (this.currentDay.thumb_nail) {
          this.currentDay.thumb_nail = this.currentDay.thumb_nail[0]?.file
        }

        this.currentDay.contents = this.currentDay.contents.map((content) => {
          if (content.alternative_exercises) {
            content.alternative_exercises = content.alternative_exercises.map(
              (alternative_exercise) => {
                return alternative_exercise.id
              }
            )
          }
          return content
        })

        const form = objectToFormData(this.currentDay)

        await this.addProgramDayExercise({
          id: this.current_program.id,
          data: form,
        })
        this.resetCurrentDay()
        this.clearExercises()
        this.enableWeek = true
      } else {
        this.current_program.daily_exercises.push(this.currentDay)
        this.resetCurrentDay()
        this.clearExercises()
        this.enableWeek = true
      }

      this.$store.commit('program/setDayLoading', false)
    },
    update() {
      this.current_program.daily_exercises[this.editIndex] = this.currentDay

      this.editIndex = null
      this.isEdit = false
      this.resetCurrentDay()
      this.clearExercises()
      this.enableWeek = true
    },
    updateEnableWeek() {
      if (this.isEditWeek) {
        this.enableWeek = true
      }
    },
    resetCurrentDay() {
      this.currentDay = {
        day: this.current_program.daily_exercises.length + 1,
        category_id: null,
        contents: [],
        thumb_nail: null,
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    loadExercises(id) {
      this.getExercises({ category: id })
    },
    loadAllExercises() {
      this.getExercises({ type: 'alt' })
    },
    loadMoreExercises() {
      if (this.exercisesMeta?.current_page < this.exercisesMeta?.last_page) {
        this.getExercises({
          loadMore: true,
          page: this.exercisesMeta.current_page + 1,
        })
      }
    },
    loadMoreAltExercises() {
      if (
        this.altExercisesStore?.meta?.current_page <
        this.altExercisesStore?.meta?.last_page
      ) {
        this.getExercises({
          loadMore: true,
          type: 'alt',
          page: this.altExercisesStore.meta.current_page + 1,
        })
      }
    },
    onEdit(data) {
      this.loadExercises(
        data.day.category_id ? data.day.category_id : data.day.category.id
      )
      this.isEdit = true
      this.editIndex = data.index
      this.currentDay = data.day
      this.enableWeek = true
    },
    async onDuplicateDay(data) {
      if (!this.isEditWeek) {
        let newDay = { ...data.day }
        newDay.day = data.day.day + 1
        this.current_program.daily_exercises?.splice(data.index, 0, newDay)
        this.current_program.daily_exercises =
          this.current_program.daily_exercises
            .sort((a, b) => a.day < b.day)
            .map((item, idx) => {
              item.day = idx + 1
              return item
            })
      } else {
        await this.duplicateProgramDayExercise({
          day_id: data.day.id,
        })
      }
      this.$emit('onDuplicateDay')
      await this.$nextTick()
      this.currentDay.day = this.current_program.daily_exercises.length + 1
    },
    async onRemove(data) {
      if (!this.isEditWeek) {
        this.current_program.daily_exercises.splice(data.index, 1)
      } else {
        await this.deleteProgramDayExercise({
          week_id: this.current_program.id,
          day_id: data.day.id,
        })
      }
    },
  },
  watch: {},
}
</script>
